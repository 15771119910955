import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { graphql } from 'gatsby';
import { customPropTypes } from '../../util';
import { useCSSMotion, useSectionTimeSpent, sanitizer } from '../../util';
import loadable from '@loadable/component';

import './Grid.scss';

const gridItemComponents = {
  icon: loadable(() => import('./IconGridItem/IconGridItem')),
  card: loadable(() => import('./CardGridItem/CardGridItem')),
  'cta-card': loadable(() => import('./CtaCardGridItem/CtaCardGridItem')),
  team: loadable(() => import('./TeamGridItem/TeamGridItem')),
};

function Grid({ data }) {
  const { columns, cardsHaveContent = false, gridType, horizontalSpacing = 'contained', name } = data;
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const gridRef = useRef(null);
  const contentMotionState = useCSSMotion(contentRef);
  const gridMotionState = useCSSMotion(gridRef);
  const GridItem = gridItemComponents[gridType || 'icon'];
  let extraOptions = {};
  useSectionTimeSpent(name, containerRef);
  if (!data.gridItems || data.gridItems.length === 0) {
    return null;
  }

  if (gridType === 'card' && cardsHaveContent) {
    extraOptions = { showBodyContent: cardsHaveContent };
  }

  return (
    <div
      id={data.id}
      className={classnames(
        'Grid',
        `grid-type-${cardsHaveContent ? 'content-' : ''}${gridType || 'icon'}`,
        `grid-columns-${columns || 3}`,
        horizontalSpacing,
      )}
      ref={containerRef}
    >
      <div className={classnames('content', contentMotionState)} ref={contentRef}>
        {data.title && <h2 className={classnames('title', { withDescription: !!data.description })}>{data.title}</h2>}
        {data.description && (
          <div className="description" dangerouslySetInnerHTML={{ __html: sanitizer(data.description) }} />
        )}
      </div>

      <div className={classnames('grid-container', gridMotionState)} ref={gridRef}>
        {data.gridItems.map((itemData, index) => {
          return (
            <div
              className={classnames('grid-item', { withHover: itemData.link || itemData.videoId || itemData.file })}
              key={index}
            >
              <GridItem data={itemData} {...extraOptions} index={index} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

Grid.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    columns: PropTypes.string,
    gridType: PropTypes.oneOf(['icon', 'card', 'team', 'cta-card']),
    title: PropTypes.string,
    description: PropTypes.string,
    gridItems: PropTypes.array,
  }).isRequired,
});

export default memo(Grid);

export const fragments = graphql`
  fragment Grid on WpPage_Flexiblelayout_FlexibleChildren_Grid {
    columns
    title
    description
    gridType
    horizontalSpacing
    cardsHaveContent
    gridItems {
      fieldGroupName
      title
      bio
      description
      name
      cardType
      file {
        altText
        title
        localFile {
          publicURL
          extension
        }
      }
      link {
        url
        title
        target
      }
      videoId
      backgroundColor
      photo {
        ...FluidImage
      }
      icon {
        ...Icon
      }
      logo {
        ...FluidImage
      }
    }
    id
    jumpToLinkTitle
    name
  }

  fragment Grid_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_Grid {
    columns
    title
    description
    gridType
    horizontalSpacing
    cardsHaveContent
    gridItems {
      fieldGroupName
      title
      bio
      description
      name
      cardType
      file {
        altText
        title
        localFile {
          publicURL
          extension
        }
      }
      link {
        url
        title
        target
      }
      videoId
      backgroundColor
      photo {
        ...FluidImage
      }
      icon {
        ...Icon
      }
      logo {
        ...FluidImage
      }
    }
    id
    jumpToLinkTitle
    name
  }
`;
